window._ = require('lodash');

 
try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');


 //-->Jqueryui
    window.jqueryui = require('jquery-ui-dist/jquery-ui.min');
    require('bootstrap');


  //-->SwiAlert2
     window.Swal = require('sweetalert2');


  //-->Alertify
     window.alertify = require('alertifyjs/build/alertify.min.js');


  //-->loading overlay
      window.loading=require('gasparesganga-jquery-loading-overlay/dist/loadingoverlay');


  //-->validation
     var validate = require('jquery-validation');

  
  //-->scrollup
  	var scrollUp= require('scrollup/dist/jquery.scrollUp');


 //-->Owlcaroosel
    const owlcaroosel = require('owl.carousel');


 //-->Jqueryui
  window.Typed = require('typed.js');

  //-->fancybox
  window.fancybox = require('@fancyapps/fancybox/dist/jquery.fancybox');
 
} catch (e) {}

 

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

 